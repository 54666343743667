import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Layout from '../components/Layout'
import Seasonal from '../components/blocks/Seasonal'
import CallToAction from '../components/blocks/CallToAction'
import Seo from '../components/Seo'
import classes from '../components/blocks/Slider.module.css'

const Prices = () => {
  const data = useStaticQuery(graphql`
    {
      allWordpressPage(limit: 1, filter: { wordpress_id: { eq: 24 } }) {
        edges {
          node {
            title
            status
            content
            wordpress_id
            featured_media {
              source_url
            }
            acf {
              banner_link
              banner_text
              call_to_action_content
              spring_open_content
              spring_open_price
              spring_open_title
              winter_close_content
              winter_close_price
              winter_close_title
              service_prices_content
            }
          }
        }
      }
    }
  `)
  const acfData = data.allWordpressPage.edges[0].node.acf;
  return (
    <Layout featuredImg={
      data.allWordpressPage.edges[0].node.featured_media.source_url
    }
    pageName={data.allWordpressPage.edges[0].node.title}>
     
      <section className="py-4 w-full lg:w-8/12 mx-auto">
        <div className="mx-auto"
          dangerouslySetInnerHTML={{
            __html: acfData.service_prices_content,
          }}
        ></div>
      </section>

      <section className="py-4 w-full">
        {/* <BannerAlt bannerText={acfData.banner_text} bannerLink={acfData.banner_link} /> */}
        <div
      className={`${classes.liningBackground} w-full overflow-x-hidden mainCarousel`}
    >
      <div
        className={`py-6 w-full h-full bg-lightBlueAlpha ${classes.carouselBox}`}
      >
        <div className="w-full">
          <div className="w-full lg:w-10/12 mx-auto flex justify-center">
            <div className="w-7/12 z-20">
              <a
                className="h2 text-darkblue h1 leading-tight"
                href={acfData.banner_link}
                target="_blank"
              >
                {acfData.banner_text}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
      </section>

      <section className="py-4 w-full lg:w-8/12 mx-auto">
        <div className="price-list mx-auto"
          dangerouslySetInnerHTML={{
            __html: data.allWordpressPage.edges[0].node.content,
          }}
        ></div>
      </section>

      <section className="py-4 w-10/12 mx-auto">
      <Seasonal 
      springContent={acfData.spring_open_content}
      springPrice={acfData.spring_open_price}
      springTitle={acfData.spring_open_title}
      winterContent={acfData.winter_close_content}
      winterPrice={acfData.winter_close_price}
      winterTitle={acfData.winter_close_title}
      />
      </section>

      <CallToAction ctaCopy={acfData.call_to_action_content} />
      <Seo title={data.allWordpressPage.edges[0].node.title} description={data.allWordpressPage.edges[0].node.content} image={data.allWordpressPage.edges[0].node.featured_media.source_url} article={true} />
    </Layout>
  )
}

export default Prices