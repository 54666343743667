import React from 'react'
import classes from './Seasonal.module.css'

const Seasonal = (props) => {
  return (
    <div className="w-full lg:flex justify-between">
      <div className={`${classes.sunBackground} lg:w-5/12`}>
        <h2 className="my-6">{props.springTitle}</h2>
        <p>
         {props.springContent}
        </p>
        <p className="pt-0">
          <strong className="text-gray-800">{props.springPrice}</strong>
        </p>
      </div>
      <div className={`${classes.snowflakeBackground} lg:w-5/12`}>
        <h2 className="my-6">{props.winterTitle}</h2>
        <p>
         {props.winterContent}
        </p>
        <p className="pt-0">
          <strong className="text-gray-800">{props.winterPrice}</strong>
        </p>
      </div>
    </div>
  )
}

export default Seasonal
